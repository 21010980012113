import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
} from "@enkor/react-query";

import {
  ApiResponseDto,
  EventListSearchParamType,
  ProjectListSearchParamType,
} from "@models/client";
import { ResponseEventInfo, ResponseProject } from "@models/data-contracts";
import BaseApi from "@logics/api/baseApi";

import { eventListQueryKeys } from "../constants/eventListQueryKeys";
import EventInfoViewModel from "../viewModel/EventViewModel";

const eventListApi = new BaseApi("");
const eventListV2Api = new BaseApi("", "v2");

// ------------------------------------------------------------------------

export const useFetchEventListInfiniteQuery = (
  params?: EventListSearchParamType,
) => {
  return useInfiniteQuery({
    queryKey: eventListQueryKeys.getEventList({ ...(params && { params }) }),
    queryFn: ({ pageParam }) =>
      eventListV2Api.get<ApiResponseDto<ResponseEventInfo>>("/event", {
        params: {
          ...params,
          page: pageParam,
        },
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      return lastPage.data.list?.length ? lastPageParam + 1 : undefined;
    },
    select: (response) => ({
      totalListCount: response.pages[0].data.totalCount,
      totalUserCount: response.pages[0].data.totalUserCount,
      list: response.pages.flatMap(({ data }) =>
        data.list.map((event) => EventInfoViewModel.create(event)),
      ),
    }),
  });
};

// ------------------------------------------------------------------------

export const useFetchProjectListQuery = (
  params?: ProjectListSearchParamType,
  options?: Omit<
    UseQueryOptions<
      ApiResponseDto<ResponseProject[]>,
      unknown,
      ResponseProject[]
    >,
    "queryKey" | "queryFn" | "select"
  >,
) => {
  return useQuery<
    ApiResponseDto<ResponseProject[]>,
    unknown,
    ResponseProject[]
  >({
    queryKey: eventListQueryKeys.getProjectList(params && { params }),
    queryFn: () =>
      eventListApi.get<ApiResponseDto<ResponseProject[]>>("/project", {
        params,
      }),
    select: ({ data }) => data,
    ...options,
  });
};
