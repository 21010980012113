import Image from "next/image";
import Link from "next/link";

import styled from "styled-components";

import { motion } from "@enkor/framer-motion";

import { getOpacityColor, grayScale, pink } from "@enkor/design/colors";
import { mediaQueries } from "@enkor/design/mixins";

const SECTION_HEIGHT = {
  WEB: 840,
  MOBILE: 640,
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
`;

// SECTION 1

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  width: 100%;
  height: ${SECTION_HEIGHT.WEB}px;
  padding: 80px 40px;

  ${mediaQueries.pad} {
    height: ${SECTION_HEIGHT.MOBILE}px;
    padding: 32px 16px;
  }
`;

export const Section1 = styled(Section)`
  position: relative;
  overflow: hidden;

  padding: 120px 40px;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    z-index: 3;

    width: 100%;
    height: 100%;

    background: linear-gradient(
      180deg,
      rgba(23, 23, 23, 0) 88.05%,
      #171717 100%
    );

    pointer-events: none;
  }

  ${mediaQueries.laptop} {
    padding: 72px 40px 0;
  }

  ${mediaQueries.pad} {
    height: auto;

    padding: 48px 16px;

    aspect-ratio: 9 / 14;
  }

  ${mediaQueries.mobile} {
    aspect-ratio: 9 / 16;
  }
`;

export const SectionContentWrapper = styled(motion.div)`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;

  width: 100%;
  max-width: 1260px;
  margin: 0 auto;

  opacity: 0;
`;

export const LogoImage = styled(Image)`
  width: 200px;
  height: 74px;

  ${mediaQueries.pad} {
    width: 99px;
    height: 36px;
  }
`;

export const MainTitleWrapper = styled.div`
  position: relative;

  display: flex;
`;

export const MainTitle = styled.h1`
  flex-shrink: 0;

  color: ${grayScale.white};
  font-weight: 800;
  font-size: 72px;
  line-height: 88px;
  white-space: pre-line;

  ${mediaQueries.pad} {
    font-size: 32px;
    line-height: 48px;
  }
`;

export const ShiningStars = styled(Image)`
  position: absolute;
  right: -86px;
  bottom: -5px;

  width: 80px;
  height: 80px;

  ${mediaQueries.pad} {
    right: -42px;
    bottom: -5px;

    width: 32px;
    height: 32px;
  }
`;

export const MainDescription = styled.p`
  color: ${grayScale.white};
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  white-space: pre-line;

  ${mediaQueries.laptop} {
    font-size: 24px;
    font-weight: 400;
  }
`;

export const AppDownloadButtonContainer = styled.div`
  z-index: 2;
  display: flex;
  gap: 27px;
  align-items: center;

  ${mediaQueries.laptop} {
    gap: 12px;
  }
`;

export const AppDownloadButton = styled.button`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;

  width: 240px;
  height: 80px;
  padding: 26px 31px 26px 40px;
  border: 1px solid ${grayScale.main};
  border-radius: 999px;

  background-color: ${grayScale[200]};
  box-shadow: 0px 0px 40px 0px ${getOpacityColor(grayScale.white, 0.15)};

  transition:
    border 0.3s ease-in,
    box-shadow 0.3s ease-in;

  span {
    color: ${grayScale.main};
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    text-align: center;

    transition: color 0.3s ease-in;

    ${mediaQueries.pad} {
      font-size: 14px;
      line-height: 20px;
    }
  }

  svg,
  path {
    fill: ${grayScale.main};

    transition: fill 0.1s ease-in;

    ${mediaQueries.pad} {
      width: 24px;
      height: 24px;
    }
  }

  &:hover {
    border: 1px solid ${pink.main};

    box-shadow:
      0px 36px 185px 0px rgba(255, 25, 136, 0.14),
      0px 15.04px 77.289px 0px rgba(255, 25, 136, 0.1),
      0px 8.041px 41.322px 0px rgba(255, 25, 136, 0.08),
      0px 4.508px 23.165px 0px rgba(255, 25, 136, 0.07),
      0px 2.394px 12.303px 0px rgba(255, 25, 136, 0.06),
      0px 0.996px 5.119px 0px rgba(255, 25, 136, 0.04);

    span {
      color: ${grayScale[700]};
    }

    svg,
    path {
      fill: ${grayScale[700]};
    }
  }

  ${mediaQueries.pad} {
    width: 140px;
    height: 52px;
    padding: 14px 16px;
  }
`;

export const MobilePhoneBackgroundImage = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: url("/assets/landing/mobile-main-with-chat.webp") transparent 50% /
    contain no-repeat;

  opacity: 0;

  /* HACK: mobile phone 이미지 조정 */
  @media (max-width: 1280px) {
    background: url("/assets/landing/mobile-main-with-chat.webp") transparent
      50% / cover no-repeat;
  }

  ${mediaQueries.pad} {
    background: url("/assets/landing/mobile-main-with-chat-mobile.webp")
      transparent 50% / cover no-repeat;
    background-position: bottom;
  }
`;

// SECTION 2

export const Section2 = styled(Section)`
  background-color: ${grayScale[100]};
`;

export const Section2ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 1260px;
  height: 100%;

  ${mediaQueries.laptop} {
    overflow: hidden;
  }
`;

export const Section2TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;

export const Section2TitleDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;

  margin: 0 auto;

  ${mediaQueries.laptop} {
    align-items: flex-start;
  }

  ${mediaQueries.pad} {
    width: 100%;
  }
`;

export const Section2Title = styled.h2`
  color: ${grayScale.white};
  font-weight: 800;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  text-transform: capitalize;
  white-space: pre-line;

  ${mediaQueries.pad} {
    font-size: 24px;
    line-height: 32px;
    text-align: left;
  }
`;

export const Section2Description = styled.p`
  color: ${grayScale.white};
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  text-transform: capitalize;
  white-space: pre-line;

  ${mediaQueries.pad} {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: left;
  }
`;

export const OngoingSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  ${mediaQueries.laptop} {
    justify-content: flex-start;
  }

  ${mediaQueries.pad} {
    padding-bottom: 40px;
  }
`;

// SECTION 3

export const Section3 = styled(Section)`
  position: relative;

  background-color: ${grayScale[100]};
`;

export const Section3ImageWrapper = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 0;

  width: 100%;
  height: 100%;
  max-height: 689px;

  opacity: 0.2;
`;

export const Section3ContentWrapper = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 80px;
  justify-content: center;
  align-items: center;

  width: 100%;

  ${mediaQueries.pad} {
    gap: 68px;
  }
`;

export const Section3Title = styled.h2`
  color: ${grayScale.white};
  font-weight: 800;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  text-transform: capitalize;
  white-space: pre-line;

  ${mediaQueries.pad} {
    font-size: 24px;
    line-height: 32px;
    text-align: left;
    margin-right: auto;
  }
`;

export const ReviewCardsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ReviewCardImage = styled.div`
  position: relative;
  z-index: 3;
  object-fit: cover;

  width: 100%;
  height: 478px;
  max-width: 1002px;

  background: url("/assets/landing/review-card-group-web.webp") transparent 50% /
    contain no-repeat;

  ${mediaQueries.pad} {
    background: url("/assets/landing/review-card-group-mobile.webp") transparent
      50% / contain no-repeat;
  }
`;

export const EventBackgroundImage = styled.div`
  position: absolute;
  bottom: 0;

  object-fit: cover;

  width: 100%;
  height: 689px;

  background:
    linear-gradient(180deg, rgba(23, 23, 23, 0) 72.11%, #171717 100%),
    url("/assets/landing/event-image-group-web.webp") transparent 50% / cover
      no-repeat;
  background-position: top;

  ${mediaQueries.pad} {
    max-height: 485px;

    background:
      linear-gradient(180deg, rgba(23, 23, 23, 0) 84.9%, #171717 100%),
      url("/assets/landing/event-image-group-mobile.webp") transparent 50% /
        cover no-repeat;
    background-position: top;
  }
`;

// SECTION 4

export const Section4 = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding: 80px 40px 200px;

  background: linear-gradient(178deg, #171717 15.6%, #333 73%, #171717 91.44%);

  ${mediaQueries.pad} {
    gap: 32px;
    padding: 32px 16px 160px;
  }
`;

export const Section4BackgroundImage = styled(Image)`
  position: absolute;
  left: -510px;
  top: 50%;
  object-fit: cover;
  width: 1157px;
  height: 1157px;

  transform: translateY(-50%);

  ${mediaQueries.laptop} {
    left: -480px;

    width: 1038px;
    height: 1038px;
  }

  ${mediaQueries.pad} {
    display: none;
  }
`;

export const Section4Title = styled(motion.h2)`
  position: sticky;
  top: 80px;

  color: ${grayScale.white};
  font-weight: 800;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  text-transform: capitalize;
  white-space: pre-line;

  ${mediaQueries.pad} {
    top: 32px;

    font-size: 24px;
    line-height: 32px;
  }
`;

export const Section4ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 1260px;

  ${mediaQueries.pad} {
    gap: 68px;
  }
`;

export const StackedCard = styled(motion.div)`
  position: sticky;
  top: calc(56px + 80px + 56px);

  display: flex;
  width: 100%;
  max-width: 1260px;
  height: 560px;
  align-items: flex-start;
  gap: 24px;
  margin: 0 auto;

  will-change: transform;
  overflow: hidden;
  transform-origin: center top;

  border-radius: 80px;
  border: 1px solid ${grayScale[300]};
  background: linear-gradient(291deg, #000 30.2%, #333 98.65%);
  box-shadow:
    0px 100px 80px 0px rgba(0, 0, 0, 0.28),
    0px 41.778px 33.422px 0px rgba(0, 0, 0, 0.2),
    0px 22.336px 17.869px 0px rgba(0, 0, 0, 0.17),
    0px 12.522px 10.017px 0px rgba(0, 0, 0, 0.14),
    0px 6.65px 5.32px 0px rgba(0, 0, 0, 0.11),
    0px 2.767px 2.214px 0px rgba(0, 0, 0, 0.08);

  ${mediaQueries.pad} {
    top: calc(32px + 32px + 32px);

    flex-direction: column;
    height: 504px;
    gap: 16px;
  }
`;

export const CardTitleDescriptionWrapper = styled.div`
  display: flex;
  padding: 80px 0px 48px 64px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  ${mediaQueries.pad} {
    padding: 32px 32px 24px;
  }
`;

export const CardTitle = styled.h3`
  color: ${grayScale.white};
  font-size: 48px;
  font-weight: 800;
  line-height: 64px;
  white-space: pre-line;

  ${mediaQueries.laptop} {
    font-size: 40px;
    line-height: 48px;
  }

  ${mediaQueries.pad} {
    font-size: 20px;
    line-height: 32px;
  }
`;

export const CardDescription = styled.p`
  color: ${grayScale[700]};
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  white-space: pre-line;

  ${mediaQueries.laptop} {
    font-size: 24px;
    line-height: 32px;
  }

  ${mediaQueries.pad} {
    font-size: 18px;
    line-height: 26px;
  }
`;

export const LottieWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 454px;
  height: 100%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-right: 24px;

  pointer-events: none;

  ${mediaQueries.pad} {
    max-width: unset;
    padding-right: 0;
  }
`;

export const ScaleDownLottieWrapper = styled(LottieWrapper)`
  max-width: 634px;
  padding-right: 0;

  ${mediaQueries.pad} {
    max-width: 473px;
    margin-top: -80px;
  }

  & > div > svg > g {
    transform: scale(0.8);
    transform-origin: center;

    ${mediaQueries.laptop} {
      transform: scale(0.7);
    }

    ${mediaQueries.laptop} {
      transform: scale(0.8);
    }
  }
`;

// SECTION 5

export const Section5 = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 120px;
  padding: 80px 40px;

  background: linear-gradient(180deg, #171717 0.04%, #171717 97.8%);

  ${mediaQueries.pad} {
    gap: 40px;
    padding: 32px 16px;
  }
`;

export const Section5ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1260px;
  justify-content: space-between;
  align-items: center;
`;

export const MockupImageContainer = styled.div`
  min-width: 454px;
  width: 598px;
  height: 548px;
  position: relative;

  ${mediaQueries.laptop} {
    display: none;
  }
`;

export const QRContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin: 0 auto;

  z-index: 1;

  ${mediaQueries.pad} {
    gap: 16px;
  }
`;

export const QRWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  ${mediaQueries.laptop} {
    gap: 40px;
  }

  ${mediaQueries.pad} {
    gap: 16px;
  }
`;

export const QRImage = styled(Image)`
  object-fit: cover;

  flex-shrink: 0;
  width: 163px;
  height: 163px;

  ${mediaQueries.laptop} {
    width: 208px;
    height: 208px;
  }

  ${mediaQueries.pad} {
    width: 99px;
    height: 99px;
  }
`;

export const QRPhrase = styled.h4`
  color: ${grayScale.white};

  text-align: center;
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;

  white-space: pre-line;

  ${mediaQueries.pad} {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const DownloadPhrase = styled.p`
  color: ${grayScale[700]};
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-transform: capitalize;
`;

export const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1260px;
  padding: 48px 56px;
  flex-direction: column;
  align-items: flex-start;
  gap: 72px;

  border-radius: 56px;
  border: 1px solid ${grayScale[300]};
  background: ${grayScale[300]};

  ${mediaQueries.pad} {
    padding: 32px;
    gap: 56px;
  }
`;

export const CopyrightWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
`;

export const CopyrightPhrase = styled.p`
  color: ${grayScale.white};

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  ${mediaQueries.pad} {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const CompanyInfo = styled.p`
  color: ${grayScale.white};

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  white-space: pre-line;

  ${mediaQueries.pad} {
    font-size: 10px;
    line-height: 14px;
  }
`;

export const FooterMenuWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  ${mediaQueries.pad} {
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
  }
`;

export const MenuList = styled.ul`
  display: flex;
  width: 120px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const MenuItem = styled(Link)`
  color: ${grayScale.white};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;

  list-style: none;
`;

export const SocialLinkWrapper = styled.div`
  display: flex;
  gap: 14px;
`;

export const SocialLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid ${grayScale[200]};

  background-color: ${grayScale.main};
`;

export const ColoredIcon = styled.div`
  width: 18px;
  height: 18px;

  fill: ${grayScale[700]};
  stroke-width: 0px;

  * {
    fill: ${grayScale[700]};
  }
`;

export const MobileMockupImage = styled(motion(Image))`
  position: absolute;
  object-fit: cover;
`;
